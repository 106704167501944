import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["scheduleFields", "countdownField","schedule", "countdown", "expiresAt", "submitButton"];
  
    connect() {
      this.toggleScheduleFields();
      this.toggleSubmitButton(); // Call on connect to set initial state
    }
  
    toggleSchedule(event) {
      this.toggleScheduleFields();
    }
  
    toggleScheduleFields() {
      const isChecked = this.scheduleTarget.checked;
      this.scheduleFieldsTarget.classList.toggle("hidden", !isChecked);
      this.countdownFieldTarget.classList.toggle("hidden", !isChecked);
       this.toggleSubmitButton(); // Call when the schedule or countdown changes
    }
  
    toggleSubmitButton() {
      // Check if countdown is checked and expires_at is filled
      const countdownChecked = this.countdownTarget.checked;
      const expiresAtFilled = this.expiresAtTarget.value !== '';
  
      if (countdownChecked && expiresAtFilled) {
        this.submitButtonTarget.disabled = false;
      } else {
        this.submitButtonTarget.disabled = false;
      }
    }
  }